import styled from "@emotion/styled"
import { Link } from "gatsby"
import React from "react"

import about from "../../assets/about.png"
import discord from "../../assets/discord.png"
import logo from "../../assets/logo.png"
import mq from "../utils/mediaQuery"

const Container = styled.div`
  ${mq({
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    marginBottom: ["16px", "32px", "32px"],
    width: "100%",
  })};
`

const LogoContainer = styled.div`
  ${mq({
    display: "flex",
    flex: 1,
  })};
`

const LogoImg = styled.img`
  ${mq({
    maxWidth: [160, 270, 270],
  })};
`

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  flex-direction: column;
  color: "#000";
  text-decoration: none;
`

const StyledA = styled.a`
  display: flex;
  align-items: center;
  flex-direction: column;
  color: "#000";
  text-decoration: none;
`

const ButtonContainer = styled.div`
  ${mq({
    display: "flex",
    justifyContent: "center",
  })};
`

const ButtonImg = styled.img`
  ${mq({
    display: "flex",
    flex: 1,
    width: [24, 38, 38],
    height: [24, 38, 38],
  })};
`

const ButtonTitle = styled.span`
  ${mq({
    color: "black",
    fontSize: [10, 14, 14],
  })};
`

const Header = () => (
  <Container>
    <LogoContainer>
      <StyledLink to="/">
        <LogoImg src={logo} alt="Logo" />
      </StyledLink>
    </LogoContainer>
    <ButtonContainer>
      <StyledLink
        to="/about"
        css={{
          marginRight: "16px",
        }}
      >
        <ButtonImg src={about} alt="About Us" />
        <ButtonTitle>About Us</ButtonTitle>
      </StyledLink>
      <StyledA href="https://discord.gg/xMMF9MeeGe">
        <ButtonImg src={discord} alt="Connect" />
        <ButtonTitle>Connect</ButtonTitle>
      </StyledA>
    </ButtonContainer>
  </Container>
)

export default Header
