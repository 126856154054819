import styled from '@emotion/styled';
import React from 'react';

import mq from '../utils/mediaQuery';

const Container = styled.div`
  ${mq({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    fontSize: [12, 16],
    marginTop: ['62px', '62px', '62px'],
    width: '100%',
  })};
`;

const Copyright = () => (
  <Container>
    <span>&#169; Copyright 2021 CryptoHexies.</span>
  </Container>
);

export default Copyright;
