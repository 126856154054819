import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';

import mq from '../utils/mediaQuery';
import Copyright from './Copyright';
import Header from './Header';

const MainContainer = styled.div`
  ${mq({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    width: '100%',
  })};
`;

const MainColumn = styled.div`
  ${mq({
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'start',
    flexDirection: 'column',
    maxWidth: ['360px', '500px', '1200px'],
    padding: ['16px', '16px', '16px'],
    marginTop: ['16px', '16px', '16px'],
    width: '100%',
  })};
`;

const Layout = ({ children }) => (
  <>
    <MainContainer>
      <MainColumn>
        <Header />
        {children}
        <Copyright />
      </MainColumn>
    </MainContainer>
  </>
);

Layout.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export default Layout;
